<template>
  <div>
<!-- Modal primario -->
    <div v-if="currentStepOne" class="modal fade" id="modalPrimaryStep" tabindex="-1" role="dialog" aria-labelledby="modalPrimaryStepLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold" id="modalPrimaryStepLabel">Incluir Etapa Secundária</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="companySteps[currentStepOne.name]" class="w-100">
                        <p class="mb-4">Qual etapa secundária você deseja iniciar?</p>
                        <div v-for="(stepTwo, index) in Object.entries(companySteps[currentStepOne.name])" :key="index" class="w-100 m-auto">
                            <div v-if="!verifyActivesCheck(currentStepOne, stepTwo[0])" class="d-flex mb-4 align-items-center">
                                <button
                                    @click="updateSecondary(currentStepOne.name, stepTwo[0])"
                                    data-dismiss="modal"
                                    class="d-flex align-items-center btn btn-sm btn-outline-success mr-2">
                                        <i class="fas fa-play mr-1" style="font-size: .7rem"></i>
                                        <span class="small">Iniciar!</span>
                                </button>
                                <p class="m-0 p-0">{{ stepTwo[1] }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>

    <!-- Modal secundario -->
    <div v-if="currentStepOne" class="modal fade" id="modalSecondaryStep" tabindex="-1" role="dialog" aria-labelledby="modalSecondaryStepLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold" id="modalSecondaryStepLabel">Encerrar Etapa Principal</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Certifique-se que todas as <strong>Etapas Secundárias ATIVAS</strong>
                    foram encerradas antes de finalizar a etapa principal.</p>
                    <p class="font-weight-bold">Você confirma a finalização da etapa?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Fechar</button>
                    <button @click="finishMainStep(currentStepOne)" type="button" data-dismiss="modal" class="btn btn-primary">Finalizar</button>
                </div>
            </div>
        </div>
    </div>

    <div class="content-wrapper px-3">
        <TitlePage 
			:title="titlePage" 
			:iconClass="iconClass" 
			:routeButton="$route.params.rd_billing ? 'companies_rd_billing' :'companies'"
		/>

        <section class="content">

            <!-- info gerais -->
            <div>
                <div v-if="company.defaulting" class="alert alert-warning" role="alert">
                    <h3>Atenção</h3>
                    <p>Esta empresa está inadimplente</p>
                </div>
            </div>



            <div class="mt-5">
                <h6 class="ml-4 text-muted">Dados Cadastrais</h6>
                <hr />
            </div>

            <div class="row">
                <div class="d-flex align-items-center col-12">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="name-company">Nome</label>
                    </div>
                    <div class="form-group p-0 m-0 mb-2 col-9 col-lg-6">
                        <input
                            type="text"
                            name="name-company"
                            id="name-company"
                            class="form-control"
                            v-model="company.name"
                        />
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="razao-social">Razão Social</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                        <input
                            type="text"
                            name="razao-social"
                            id="razao-social"
                            class="form-control"
                            v-model="company.razao_social"
                        />
                    </div>
                </div>

                <div v-if="states && company.address && company.address.state" class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="select-state">Estado</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                        <select id="select-state" class="form-control" v-model="company.address.state">
                            <option value="">Selecione...</option>
                            <option v-for="(state, i) in states" :key="i" :value="state.acronym">
                                {{ state.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div v-if="states && company.address && company.address.city" class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="select-city">Cidade</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6" >
                        <select id="select-city" class="form-control" v-model="company.address.city" >
                            <option value="">Selecione...</option>
                            <option v-for="(city, i) in citiesBasic" :key="i" :value="city">
                                {{ city }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="focal-contact">Contato Focal</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                        <input
                            type="text"
                            name="focal-contact"
                            id="focal-contact"
                            class="form-control"
                            v-model="company.focal_contact"
                        />
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="email-company">Email</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                        <input
                            type="text"
                            name="email-company"
                            id="email-company"
                            class="form-control"
                            v-model="company.email"
                        />
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="phone-company">Telefone</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                        <input
                            type="text"
                            name="phone-company"
                            id="phone-company"
                            class="form-control"
                            v-model="company.phone"
                        />
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="select-department">Segmento</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6" >
                        <select id="select-department" class="form-control" v-model="company.department" >
                            <option value="">Selecione...</option>
                            <option v-for="(segment, i) in segments.branches_of_activity" :key="i" :value="segment">
                                {{ segment }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="mt-5">
                <h6 class="ml-4 text-muted">Dados da Plataforma</h6>
                <hr />
            </div>

            <div class="row">
                <div class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="select-plans">Tipo de Conta</label>
                    </div>
                    <div>
                        <p class="text-muted m-0 pb-1">{{ translateAccountType(company.account_type) }}</p>
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center mt-3">
                    <div class="mr-2 col-3">
                        <label class="word-custom">Data de criação</label>
                    </div>
                    <div>
                        <p class="text-muted m-0 pb-1">{{ moment(company.created_at).format('DD/MM/YYYY HH:mm:ss') }}</p>
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center mt-3">
                    <div class="mr-2 col-3">
                        <label class="word-custom">Último Acesso</label>
                    </div>
                    <div>
                        <p class="text-muted m-0 pb-1">{{ moment(company.last_access_in_platform).format('DD/MM/YYYY HH:mm:ss') }}</p>
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center mt-3">
                    <div class="mr-2 col-3">
                        <label class="word-custom">Possui Whatsapp Oficial</label>
                    </div>
                    <div>
                        <p v-if="company.whatsapps_integrations_type === 'official'" class="text-muted m-0 pb-1">Sim</p>
                        <p v-if="company.whatsapps_integrations_type === 'tallos'" class="text-muted m-0 pb-1">Não</p>
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center mt-3">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="registered-employees">Usuários Cadastrados</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                        <p class="text-muted m-0 pb-1">{{ totalEmployees }}</p>
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="engaged-employees">Usuários Contratados</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                        <input
                            type="number"
                            name="engaged-employees"
                            id="engaged-employees"
                            class="form-control"
                            style="width: 130px;"
                            min="0"
                            max="99"
                            v-model="company.engaged_employees"
                        />
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom">Engajado</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                        <p v-if="company.message && company.message.created_at" class="text-muted m-0 pb-1">{{ companyEngagement(company.message) ? 'Sim' : 'Não' }}</p>
                        <p v-else class="text-muted m-0 pb-1">Não</p>
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="select-plans">Possui PABX</label>
                    </div>
                    <div class="btn-group-account-type" >
                        <select id="select-plans" class="form-control" v-model="company.has_pabx">
                            <option :value="true">Sim</option>
                            <option :value="false">Não</option>
                        </select>
                    </div>
                </div>

                <div class="d-flex col-12 align-items-center mt-3">
                    <div class="mr-2 col-3">
                        <label class="word-custom" for="branch-registered">Ramais Contratados</label>
                    </div>
                    <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                        <input
                            type="number"
                            name="branch-registered"
                            id="branch-registered"
                            class="form-control"
                            style="width: 130px;"
                            min="0"
                            max="99"
                            v-model="company.contracted_extensions"
                        />
                    </div>
                </div>
            </div>

            <!-- inadimplente -->
            <div class="mt-5">
                <h6 class="ml-4 text-muted">Situação Atual</h6>
                <hr />

                <div class="row">
                    <div class="d-flex col-12 align-items-center mt-2">
                        <div class="mr-2 col-3">
                            <label class="word-custom">Inadimplente</label>
                        </div>
                        <div>
                            <select id="select-plans" class="form-control" v-model="company.defaulting">
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <!-- salvar info gerais -->
                <div class="row mt-5">
                    <div class="col-lg-12 my-2 d-flex justify-content-center mb-4">
                        <router-link 
                            :to="{ 
                                name: $route.params.rd_billing ? 'companies_rd_billing' :'companies', 
                                params: {}
                            }"
                            class="btn btn-outline-info btn-big" 
                            title="Voltar">
                            <i class="fa fa-arrow-left mx-1"></i> Voltar
                        </router-link>
                        <button class="btn-big btn btn-primary ml-3" @click="updateCompany()">
                            Atualizar Informações
                        </button>
                    </div>
                </div>

            <!-- ----------- CHURN E JORNADA --------------- -->

            <!-- churn -->
            <div class="mt-5">
                <h6 class="ml-4 text-muted">Informação de Churn</h6>
                <hr />

                <div class="ml-4" v-if="!showChurn && !company.churn_info">
                    <a class="text-primary" @click="initChurn()" style="cursor: pointer">+ cadastrar churn</a>
                </div>

                <div v-else>
                    <!-- motivo -->
                    <div class="row">
                        <div class="d-flex col-12 align-items-center mt-2">
                            <div class="mr-2 col-3">
                                <label for="input-reason" class="word-custom">Motivo</label>
                            </div>
                            <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="input-reason"
                                    placeholder="Motivo geral do churn"
                                    v-model="company.churn_info.reason">
                            </div>
                        </div>
                    </div>

                    <!-- descrição -->
                    <div class="row">
                        <div class="d-flex col-12 align-items-center mt-2">
                            <div class="mr-2 col-3">
                                <label for="input-description" class="word-custom">Descrição</label>
                            </div>
                            <div class="form-group m-0 p-0 mb-2 col-9 col-lg-6">
                                <textarea
                                    class="form-control"
                                    id="input-description"
                                    rows="3"
                                    placeholder="Descrição mais detalhada"
                                    v-model="company.churn_info.description"></textarea>
                            </div>
                        </div>
                    </div>

                    <!-- nivel -->
                    <div class="row">
                        <div class="d-flex col-12 align-items-center mt-2">
                            <div class="mr-2 col-3">
                                <label for="select-level" class="word-custom">Nível do Churn</label>
                            </div>
                            <div>
                                <select id="select-level" class="form-control" v-model="company.churn_info.level">
                                    <option value="normal">Normal</option>
                                    <option value="critical">Crítico</option>
                                    <option value="urgency">Urgência</option>
                                    <option value="emergency">Emergência</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- informações -->
                    <div class="row">
                        <div class="d-flex col-12 align-items-center mt-2">
                            <div class="text-muted mt-3 ml-2 small">
                                <p v-if="company.churn_info.created_by" class="m-0 p-0">
                                    Registrado por {{ company.churn_info.created_by.name }} em {{ convertDate(company.churn_info.created_at) }}
                                </p>
                                <p v-if="company.churn_info.edited_by" class="m-0 p-0">
                                    Editado por {{ company.churn_info.edited_by.name }} em {{ convertDate(company.churn_info.edited_at) }}
                                </p>
                            </div>
                        </div>
                    </div>



                    <!-- salvar churn-->
                    <div class="row mt-5">
                        <div class="col-lg-12 my-2 d-flex justify-content-center mb-4">
                            <button @click="showChurn=false" class="btn-big btn btn-default">
                                Cancelar
                            </button>
                            <button class="btn-big btn btn-primary ml-3" @click="createUpdateChurn()">
                                Salvar Churn
                            </button>
                        </div>
                    </div>
                </div>


            </div>

            <!-- nova visualização -->
            <div class="mt-5">
                <h6 class="ml-4 mt-2 text-muted">Fase do cliente</h6>
                <hr />

                <div class="row">
                    <div class="m-0 p-0 col-12">
                        <div class="w-100">
                            <div v-for="(step, index) in currentSteps" :key="index" class="d-flex card m-0 shadow-none p-0" style="margin-bottom: 0 !important">
                                <div style="min-height: 6.25rem">
                                    <div class="d-flex align-items-center justify-content-center h-100">

                                        <!-- bolinha -->
                                        <div class="col-1 d-flex justify-content-center align-items-center h-100 m-0 p-0">
                                            <span v-if="!step.info" class="circle position-absolute bg-secondary"></span>
                                            <span v-else-if="!step.info.finish" class="circle position-absolute bg-warning"></span>
                                            <span v-else class="circle position-absolute bg-success"></span>
                                        </div>

                                        <!-- iniciar etapa -->
                                        <div class="col-11 d-flex flex-wrap align-items-center m-0 p-0 w-100">
                                            <!-- info da etapa -->
                                            <div class="col-12 m-0 p-0 w-100">
                                                <div class="d-flex flex-wrap align-items-center">
                                                    <!-- coluna 01 -->
                                                    <div class="w-100 col-12 col-lg-6 d-flex flex-wrap align-items-center m-0">
                                                        <!-- botao iniciar -->
                                                        <div class="col-12 col-lg-4 m-0 mt-4 mb-2 mb-lg-0 mt-lg-0 p-0 w-100">
                                                            <!-- botao inicial -->
                                                            <button @click="initMainStep(step.name)" v-if="!step.info" class="d-flex align-items-center btn btn-sm btn-success">
                                                                <i class="fas fa-play mr-2"></i>
                                                                <span>Iniciar!</span>
                                                            </button>

                                                            <!-- botao concluir -->
                                                            <button
                                                                @click="currentStepOne=step"
                                                                v-else-if="!step.info.finish"
                                                                type="button"
                                                                class="d-flex align-items-center btn btn-sm btn-outline-warning"
                                                                data-toggle="modal"
                                                                data-target="#modalSecondaryStep">
                                                                    <i class="far fa-calendar-check mr-2"></i>
                                                                    <span class="text-dark">Concluir</span>
                                                            </button>

                                                            <!-- botao concluido -->
                                                            <button v-else class="d-flex align-items-center btn btn-sm btn-outline-success" disabled>
                                                                <i class="fas fa-check mr-2"></i>
                                                                <span class="text-dark">Concluido!</span>
                                                            </button>
                                                        </div> 
                                                        <div class="col-12 col-lg-8 m-0 p-0">
                                                            <!-- titulo -->
                                                            <p class="m-0 p-0 font-weight-bold">{{ translateSteps(step.name) }}</p>
                                                            <p class="m-0 p-0 small mb-2">{{step.description}}</p>
                                                            
                                                            <!-- informações -->
                                                            <div v-if="step.info" class="p-0 m-0">
                                                                
                                                                <!-- informações de start -->
                                                                <div v-if="step.info.start" 
                                                                    class="d-flex align-items-center mr-2 small"
                                                                    :class="statusControlClass(step).textStart">
                                                                        <span class="mr-2">{{ `Iniciada por ${step.info.start_by.name}` }}</span>
                                                                        <i class="far fa-calendar mr-1"
                                                                            :class="statusControlClass(step).iconStart"></i>
                                                                        <span>{{step.info ? convertDate(step.info.start) : '' }}</span>
                                                                </div>

                                                                <!-- informações de finish -->
                                                                <div v-if="step.info.finish" 
                                                                    class="d-flex align-items-center small"
                                                                    :class="statusControlClass(step).textEnd">
                                                                        <span class="mr-2">{{ `Finalizada por ${step.info.finish_by.name}` }}</span>
                                                                        <i class="far fa-check-circle mr-1 text-success"
                                                                            :class="statusControlClass(step).iconEnd"></i>
                                                                        <span>{{step.info ? convertDate(step.info.finish) : '' }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- coluna 02 -->
                                                    <div v-if="step.info" class="w-100 col-12 col-lg-6 mb-4 mt-3 mt-lg-0">

                                                        <!-- exibe secundarias se existir etapas ou se a etapa ainda nao foi finalizada -->
                                                        <p v-if="!step.info.finish &&companySteps[step.name]" class="m-0 p-0 font-weight-bold">Etapas secundárias</p>
                                                        
                                                        
                                                        <!-- renderiza lista de secundaria se existir -->
                                                        <div v-if="companySteps[step.name]" class="w-100">
                                                            <div v-for="(stepTwo, index) in startedSecundary(step.name)" :key="index" class="w-100 m-auto">
                                                                <div class="w-100 mb-3 mt-3">
                                                                    <!-- botoes -->
                                                                    <div class="d-flex align-items-center">
                                                                        <!-- botao concluir -->
                                                                        <button 
                                                                            v-if="!stepTwo.finish"
                                                                            @click="updateSecondary(step.name, stepTwo.name)"
                                                                            class="btn btn-sm btn-outline-warning"
                                                                            style="padding: .1rem .6rem .1rem .6rem; font-size: .7rem">
                                                                                Concluir
                                                                        </button>

                                                                        <!-- botao de concluido -->
                                                                        <button 
                                                                            v-else
                                                                            disabled
                                                                            class="btn btn-sm btn-outline-success"
                                                                            style="padding: .1rem .6rem .1rem .6rem; font-size: .7rem; margin-top: 2px">
                                                                                concluído
                                                                        </button>


                                                                        <p class="m-0 p-0 ml-2 font-weight-bold">{{companySteps[step.name][stepTwo.name]}}</p>
                                                                    </div>

                                                                    <!-- descricao -->
                                                                    <div class="mt-2">


                                                                        <!-- iniciado -->
                                                                        <p v-if="stepTwo.start" class="d-flex align-items-center m-0 p-0 small text-muted">
                                                                            <i class="fas mr-1" :class="stepTwo.finish ? 'fa-play' : 'fa-hourglass'" style="font-size: .6rem"></i>
                                                                            <span>Iniciada por {{stepTwo.start_by.name}} em {{ convertDate(stepTwo.start) }}</span>
                                                                        </p>

                                                                        <!-- finalizado -->
                                                                        <p v-if="stepTwo.finish" class="d-flex align-items-center m-0 p-0 small text-muted">
                                                                            <i class="fas fa-check mr-1 text-success" style="font-size: .6rem"></i>
                                                                            <span>Finalizada por {{stepTwo.finish_by.name}} em {{ convertDate(stepTwo.finish) }}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Button trigger modal -->
                                                        <button
                                                            v-if="!step.info.finish && companySteps[step.name] && checkRemainingSteps(step)" 
                                                            @click="currentStepOne=step"
                                                            type="button"
                                                            class="btn btn-sm btn-primary mt-2 w-50"
                                                            style="padding: .1rem .7rem .1rem .7rem"
                                                            data-toggle="modal"
                                                            data-target="#modalPrimaryStep">
                                                                + adicionar etapa
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="statusCompanySteps.length > 0" class="m-2 mt-2">
                <h6 class="ml-4 text-muted">Fases</h6>

                <div class="card-body table-responsive p-0" style="max-height: 70vh;">
                    <table class="table table-hover table-stripped table-head-fixed">
                        <thead>
                                <tr>
                                    <th>Funcionário</th>
                                    <th>Etapa</th>
                                    <th>Início</th>
                                    <th>Fim</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(step, i) in currentStatusCompanySteps" :key="i">
                                    <td>{{ step.manager && step.manager.name ? step.manager.name : '...' }}</td>
                                    <td>{{ step.status_step_one && step.status_step_two ? `${step.status_step_one}/${step.status_step_two}` : step.status_step_one }}</td>
                                    <td>{{ moment(step.created_at).format('DD/MM/YYYY HH:mm:ss') }}</td>
                                    <td>{{ step.ended_at ? moment(step.ended_at).format('DD/MM/YYYY HH:mm:ss') : '...' }}</td>
                                </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
  </div>
</template>

<script>
// ### import libs ### 
import citiesBasic from "@/assets/scripts/citiesBasic.json";
import states from "@/assets/scripts/states.json";
import segments from '@/assets/scripts/segments.json'
import companySteps from '@/assets/scripts/companySteps.json'
import helpers from '@/assets/helpers'
import moment from 'moment-timezone'
import "moment/locale/pt-br.js"

// ### import components ###
import TitlePage from '../../shared/components/title-page/TitlePage.vue'

export default {
    components: {
		TitlePage
	},
    data() {
        return {
            titlePage: 'Informações da Empresa',
      		iconClass: 'fa fa-info-circle',
            company: {},
            churn: {},
            states,
			citiesBasic,
            segments,
			helpers,
            moment,
            totalEmployees: 0,

            statusCompanySteps: {},

            // array para gerar os steps
            defaultSteps: [
                "juridic",
                "payment",
                "adoption",
                "pabx",
                "retention",
                "expansion",
                "brand_defenders",
            ],

            defaultStepsDescription: {
                juridic: "Etapa onde o cliente está fechando contrato",
                payment: "Etapa de pagamento para começar utilização",
                adoption: "Etapa de adoção e adaptação do cliente a plataforma",
                pabx: "Etapa onde ficam os clientes que solicitaram PABX",
                retention: "Etapa de acompanhamento e retenção de cliente",
                expansion: "Etapa de expansão, onde o cliente se encontra consolidado com a plataforma",
                brand_defenders: "Etapa de defensores da marca"
            },

             /* etapas da empresa */
            companySteps,

            /* auxiliar da etapa principal: salvar etapa aqui quando clicar no modal */
            currentStepOne: null,

            /* mostrar cadastro de churn */
            showChurn: false,

        }
    },

    computed: {
        /**
         * retorna etapas traduzidas
         */
        currentStatusCompanySteps(){
            return this.statusCompanySteps.map(step => {
                const stepOne = step.status_step_one
                const stepTwo = step.status_step_two
                if(stepOne){
                    step.status_step_two = stepTwo && this.companySteps[stepOne] && this.companySteps[stepOne][stepTwo] ?
                        this.companySteps[stepOne][stepTwo] :
                        ''
                    step.status_step_one = this.translateSteps(step.status_step_one)
                }
                return step
            })
        },

        /**
         * populando informações de mudança de fase
         * @returns {Array} array com as informações das etapas
         */
        currentSteps: function(){
            const stepsInfo = this.statusCompanySteps.steps ? this.statusCompanySteps.steps : null
            const steps = this.defaultSteps.map(step => {
                const foundStep = stepsInfo ? stepsInfo.find(stepInfo => step === stepInfo.name) : null
                return {
                    name: step,
                    description: this.defaultStepsDescription[step],
                    info: foundStep ? foundStep : null
                }
            })
            return steps
        }
    },
    
    methods: {

        /* ------------- HELPERS --------------------- */

        selectAccountType() {
			if (this.company.account_type == 'basic') {
				this.company.account_is_trial = false
			}
		},

        companyEngagement(message) {
            return this.moment().diff(this.moment(message.created_at), 'hours') > 24 ? false : true
        },


        translateAccountType(account_type) {
            switch (account_type) {
                case 'trial':
                    return 'Trial'
                case 'voip':
                    return 'Voip'
                case 'premium':
                    return 'Premium'
                case 'basic':
                    return 'Básico'
                case 'essential':
                    return 'Light'
                case 'advanced':
                    return 'Basic'
                case 'professional':
                    return 'Pro'
            }
        },

        translateSteps(step){
            switch (step) {
                case 'payment':
                    return 'Pagamentos'
                case 'adoption':
                    return 'Adoção'
                case 'juridic':
                    return 'Jurídico'
                case 'retention':
                    return 'Retenção'
                case 'expansion':
                    return 'Expansão'
                case 'brand_defenders':
                    return 'Defensores da Marca'
                case 'pabx':
                    return 'PABX'
            }
        },

        /**
        * Metodo para pegar uma data string (2021-02-25T16:26:02.458Z) converte para timestamp e formata usando intl
        * @param {String} date data a ser formatada
        * @return {Date} Retorna uma data formatada como 22/10/1990
        */
        convertDate: function (date) {
            var dateParse = new Date(date).getTime();
            var currentDate = new Intl.DateTimeFormat('pt-BR', {
                // https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                timeZone: 'America/Fortaleza'
            }).format(dateParse)

            return currentDate
        },

        /**
         * Metodo para mudar estilo de acordo com o status
         */
        statusControlClass: function(step){
            if(step.info.start && !step.info.finish){
                return {
                    
                    iconStart: 'text-success',
                    textStart: 'text-dark'
                }
            }
            if(step.info.start && step.info.finish){
                return {
                    iconEnd: 'text-success',
                    iconStart: 'text-muted',
                    textEnd: 'text-dark',
                    textStart: 'text-muted'
                }
            }
        },

        /**
         * Metodo que retorna um array de etapas secundarias ativas
         * @param {String} step nome da etapa principal
         */
        startedSecundary: function(step){
            const foundStep = this.currentSteps.find(curStep => curStep.name === step)
            return foundStep.info.secondary.filter(curStep => curStep.start)
        },

        /**
         * Metodo para checar se todas as etapas foram iniciadas
         * @param {Object} step etapa atual
         */
        checkRemainingSteps: function(step){
            const nonCctives = step.info.secondary.filter(curStep => !curStep.start)
            return nonCctives.length ? true : false
        },


        /**
         * Metodo para iniciar o churn
         */
        initChurn: function(){
            this.showChurn=true
            this.company.churn_info = {}
            this.company.churn_info.reason = ''
            this.company.churn_info.description = ''
            this.company.churn_info.level = ''
        },

        /* ------------ MONTAGEM DE REQUISIÇÃO: PRINCIPAIS METODOS --------------- */

        /**
         * Metodo que recebe o nome da etapa para iniciar.
         * @param {String} step nome da etapa 
         */
        initMainStep: function(step){
            
            /* verifica se possui etapas secundarias. se sim, preenche o array da requisição */
            const secondarySteps = this.companySteps[step] ? Object.keys(this.companySteps[step]) : null
            if(secondarySteps){
                var secondary = secondarySteps.map(stepTwo => ({ name: stepTwo, active: false }))
            }

            /* objeto da requisição */
            const toUpdate = {
                name: step,
                start_by: this.$store.getters.admin.id,
                secondary: secondary ? secondary : []
            }

            this.updateJourneyStep(toUpdate)

        },


        /**
         * Metodo para finalizar a etapa principal
         * @param {Object} step objeto com informações da etapa
         */
        finishMainStep: function(step){
            /* objeto da requisição */
            const toUpdate = {
                name: step.name,
                finish_by: this.$store.getters.admin.id,
            }
            this.updateJourneyStep(toUpdate)
        },


        /**
         * Metodo que recebe as informações para iniciar ou terminar uma sub etapa 
         * @param {String} step nome da etapa principal do cliente
         * @param {String} stepTwoName nome da etapa secundaria
         */
        updateSecondary: function(step, stepTwoName){

            const foundStep = this.currentSteps.find(curStep => curStep.name === step)
            if(!foundStep){
                this.$toasted.global.defaultError({ msg: 'Não foi possivel encontrar a etapa.'})
                return console.debug("Parece que a etapa não foi encontrada na lista. Lista atual >> ", this.currentSteps)
            }

            const foundStepTwo = foundStep.info.secondary.find(curStep => curStep.name === stepTwoName)

            /* se não existe uma etapa secundaria ou ainda nao foi iniciada, é criada/atualizada agora. */
            if(!foundStepTwo || !foundStepTwo.start){
                const toUpdate = {
                    name: step,
                    secondary: {
                        name: stepTwoName,
                        start_by: this.$store.getters.admin.id,
                    }
                }
                return this.updateSecondaryStep(toUpdate)
            }

            /* verifica se já concluiu. */
            if(foundStepTwo.finish){
                this.$toasted.global.defaultError({ msg: 'A etapa já foi concluída'})
                return console.debug("A etapa já foi concluida e não é possivel fazer alterações")
            }

            /* finalizar a etapa */
            const toUpdate = {
                name: step,
                secondary: {
                    name: stepTwoName,
                    finish_by: this.$store.getters.admin.id,
                }
            }
            return this.updateSecondaryStep(toUpdate)

        },


        /**
         * Metodo para montara requisição de churn
         * @param {Object} step objeto com informações do churn
         */
        createUpdateChurn: function(){
            
            /* se ainda não existir churn */
            if(!this.company.churn_info || !this.company.churn_info.created_at){

                /* objeto da requisição */
                const toUpdate = {
                    reason: this.company.churn_info.reason,
                    description: this.company.churn_info.description,
                    level: this.company.churn_info.level,
                    created_by: this.$store.getters.admin.id,
                }
                this.updateChurn(toUpdate)
            } else {

                /* objeto da requisição */
                const toUpdate = {
                    reason: this.company.churn_info.reason,
                    description: this.company.churn_info.description,
                    level: this.company.churn_info.level,
                    edited_by: this.$store.getters.admin.id,
                }
                this.updateChurn(toUpdate)
            }
        },

        /**
         * Metodo para verificar se a etapa ja esta ativa
         */
        verifyActivesCheck: function(stepTwo, name){
            const found = stepTwo.info.secondary.find(step => step.name === name ? step.start : false )
            return found ? true : false
        },

        /* ----------------- REQUISIÇÕES ------------------------- */

        /**
        Metodo para atualizar as informações da empresa
         */
        updateCompany: function() {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;
            this.$tallos_admin_api
                .put(`companies/simple/update/${this.$route.params.id}/${this.$store.getters.admin.id}`, this.company)
                .then(() => {
                    this.$toasted.global.defaultSuccess({msg: 'Os dados foram atualizados com sucesso.'})
                    this.companyJourney()
                    this.getCompany()
                })
                .catch(() => this.$toasted.global.defaultError({ msg: 'Erro ao atualizar.'}))
        },

        /**
         * Chamada da api para buscar as informações da jornada do cliente
         */
        companyJourney: function() {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;

            this.$tallos_admin_api
                .get(`status-company-steps/find/journey/${this.$route.params.id}`)
                .then(res => this.statusCompanySteps = res.data)
                .catch(e => {
                    console.debug('[jornada] parece que a empresa não tem jornada.', e)
                })
        },

        /**
        * Metodo para atualizar uma etapa secundaria
        * @param {Object} toUpdate Objeto contendo informações para atualização
        */
        updateJourneyStep: function(toUpdate){
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;

            this.$tallos_admin_api
                .put(`status-company-steps/update/journey/${this.$route.params.id}`, toUpdate)
                .then(res => {
                    this.$toasted.global.defaultSuccess({msg: 'O status foi atualizado com sucesso.'})
                    console.debug('[jornada] ',res.data.message)
                    this.companyJourney()
                })
                .catch(e => {
                    console.debug('[jornada] Não foi possivel atualizar a etapa principal.', e)
                    this.$toasted.global.defaultError({msg: e.response.data.message})
                })
        },

        /**
         * Chamada da api para atualizar o status da etapa secundaria
         * @param {{name: String, secondary: {name: String, updated_by: String, active: Boolean}}} toUpdate Objeto contendo informações da requisição
         */
        updateSecondaryStep: function(toUpdate) {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;

            this.$tallos_admin_api
                .put(`status-company-steps/update/secondary/${this.$route.params.id}`, toUpdate)
                .then(res => {
                    this.companyJourney()
                    console.debug('[jornada] ',res.data.message)
                    this.$toasted.global.defaultSuccess({msg: 'Etapa secundária atualizada.'})
                })
                .catch(e => {
                    console.debug('[jornada] Não foi possivel atualizar a etapa secundária.', e)
                    this.$toasted.global.defaultError({msg: 'Não foi possivel atualizar a etapa secundária.'})
                })
        },


         /**
        * Metodo para atualizar o churn
        * @param {Object} toUpdate Objeto contendo informações para atualização
        */
        updateChurn: function(toUpdate){
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
                `Bearer ${this.$store.getters.tokenJWT}`;

            this.$tallos_admin_api
                .put(`companies/update/churn/${this.$route.params.id}`, toUpdate)
                .then(res => {
                    this.$toasted.global.defaultSuccess({msg: 'O status foi atualizado com sucesso.'})
                    console.debug('[churn] ', res.data.message)
                    this.getCompany()
                })
                .catch(e => {
                    console.debug('[churn] Não foi possivel atualizar as informações de churn.', e)
                    this.$toasted.global.defaultError({msg: e.response.data.message})
                })
        },

        getTotalEmployees: function() {
            this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.get(`employees/count/${this.$route.params.id}`)
				.then(res => this.totalEmployees = res.data)
				.catch(e => this.$toasted.global.defaultError({msg: 'Erro na busca de dados.'}))
        },

        getCompany: function() {
			this.$tallos_admin_api.defaults.headers.common[ "Authorization"] =
				`Bearer ${this.$store.getters.tokenJWT}`;

			this.$tallos_admin_api
				.get(`companies/${this.$route.params.id}`)
				.then(res => {
                    this.company = res.data.company
                    this.company['message'] = res.data.last_message
                })
				.catch(e => {
					this.$toasted.global.defaultError({msg: 'Empresa não encontrada.'})
					this.$router.push({ path: '/admin/companies' })
				})
		},
    },

    created: function() {
        this.getCompany()
        this.companyJourney()
        this.getTotalEmployees()
        document.title = 'Tallos Admin - Informações Empresa'
    }
}
</script>

<style scoped>
.content-wrapper {
    background-color: #fff;
}

.vertical-line {
    display: block;
    height: 100%;
    border-right: 4px solid rgb(168, 168, 168);
}

.circle {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
</style>
